import {ECOM_MEMBERS_AREA_DEFAULT_PAGES, FedopsInteractions, SPECS} from '../constants';
import {
  addApplications,
  installMembersArea,
  registerMembersAreaApps,
  isMembersAreaInstalled,
} from '@wix/members-area-integration-kit';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomComponentsData, EcomPlatformInstallationState} from '../services/EcomPlatformInstallationState';
import {getEcomPagesConfiguration} from '../../common/appManifest/appManifest';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {StyleParams} from '../services/StyleParams';
import {ContextParams, EditorSDK, PageRef, PopupData, RouterRef} from '@wix/platform-editor-sdk';
import {EcomStyleParams} from '@wix/ecom-platform-sdk/dist/es/src/types';
import {ExperimentsApi} from '../../common/experiments/ExperimentsApi';
import {createWishlistApi, InstallationOrigin} from './wishlist/wishlistActions';
import {DependantApps} from '../services/DependantApps';
import {translateFunctionFactory} from '../../common/translations/translations';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {WIX_ECOM, WIX_NEW_STORES} from '@wix/app-definition-ids';
import {getEcomPages} from '../../common/pages';
import {AnyFunction} from '@wix/wixstores-client-core';
import {createLightbox} from './sideCartLightbox';
import {PageData} from '@wix/editor-platform-sdk-types';

interface PageDataType {
  compId: string;
  pageRef: PageRef;
  pageUriSEO: string;
  title: string;
}

export async function addPage({
  sdk,
  pageId,
  managingAppDefId,
  shouldNavigate,
  fedopsLogger,
  reportError,
}: {
  sdk: EditorSDK;
  pageId: string;
  managingAppDefId: string;
  shouldNavigate: boolean;
  fedopsLogger: Record<string, any>;
  reportError: AnyFunction;
}) {
  let pageData: PageDataType;
  try {
    managingAppDefId = pageId === PageMap.CHECKOUT ? WIX_ECOM : managingAppDefId;
    try {
      fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddPage);
      pageData = (await sdk.tpa.add.component('', {
        appDefinitionId: WIX_ECOM,
        managingAppDefId,
        componentType: sdk.document.tpa.TPAComponentType.Page,
        page: {
          pageId,
          shouldNavigate,
        },
      })) as PageDataType;

      fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddPage);
    } catch (err) {
      fedopsLogger?.interactionFailed?.(FedopsInteractions.EcomPlatformPublicApiAddPage, err as Error);
    }

    try {
      fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddPageSetState);
      await sdk.document.pages.setState('', {
        state: {
          [pageId]: [{id: pageData.pageRef.id} as PageRef],
        },
      });
      fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddPageSetState);
    } catch (err) {
      fedopsLogger?.interactionFailed?.(FedopsInteractions.EcomPlatformPublicApiAddPageSetState, err as Error);
    }
    return pageData.pageRef;
  } catch (e) {
    reportError(e);
  }
}

export function addWidget(
  sdk: EditorSDK,
  widgetId: string,
  addToAllPages: boolean,
  fedopsLogger: Record<string, any>,
  reportError: AnyFunction
) {
  try {
    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddWidget);
    const compData = sdk.tpa.add.component('', {
      appDefinitionId: WIX_ECOM,
      componentType: sdk.tpa.TPAComponentType.Widget,
      widget: {
        widgetId,
        allPages: addToAllPages,
      },
    });
    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddWidget);

    return compData;
  } catch (e) {
    fedopsLogger?.interactionFailed?.(FedopsInteractions.EcomPlatformPublicApiAddWidget, e as Error);
    reportError(e);
  }
}

export async function addLightbox({
  sdk,
  lightboxId,
  managingAppDefId,
  fedopsLogger,
  reportError,
  experiments,
  contextParams,
  t,
}: {
  sdk: EditorSDK;
  lightboxId: string;
  managingAppDefId: string;
  fedopsLogger: Record<string, any>;
  reportError: AnyFunction;
  experiments: ExperimentsApi;
  contextParams: ContextParams;
  t: (key: string) => string;
}) {
  const isResponsive = contextParams.origin.type === 'RESPONSIVE';

  try {
    let pageData: {pageRef: PageRef};
    if (lightboxId === PageMap.SIDE_CART) {
      if (!experiments.enabled(SPECS.ShouldInstallSideCart)) {
        return;
      }

      fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddLightbox);
      pageData = {pageRef: await addSideCart(sdk, managingAppDefId, isResponsive, t)};
    } else {
      fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddLightbox);
      reportError(Error(`Failed to add lightbox, lightbox id - ${lightboxId} not found`));
      return;
    }
    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddLightbox);

    return pageData.pageRef;
  } catch (e) {
    fedopsLogger?.interactionFailed?.(FedopsInteractions.EcomPlatformPublicApiAddLightbox, e as Error);
    reportError(e);
  }
}

export function getRouterRefByPrefix(sdk: EditorSDK, prefix: string) {
  return sdk.routers.getByPrefix('', {prefix});
}

export async function addRouter(sdk: EditorSDK, prefix: string, reportError: AnyFunction) {
  try {
    const routerRef = await getRouterRefByPrefix(sdk, prefix);
    if (routerRef) {
      return routerRef;
    }

    return await sdk.routers.add('', {prefix});
  } catch (e) {
    reportError(e);
  }
}

export function getRouter(sdk: EditorSDK, routerRef: RouterRef) {
  return sdk.routers.get('', {routerRef});
}

export function connectPageToRouter(
  sdk: EditorSDK,
  {pageRef, routerRef, pageRoles}: {pageRef: PageRef; routerRef: RouterRef; pageRoles: string[]},
  reportError: AnyFunction
) {
  return sdk.routers.pages
    .connect('', {
      pageRef,
      routerRef,
      pageRoles,
    })
    .catch((e) => {
      reportError(e);
    });
}

export const toMonitored = async <T>(
  fedopsLogger: Record<string, any>,
  interactionName: string,
  action: () => Promise<T> | T
): Promise<T> => {
  try {
    fedopsLogger?.interactionStarted?.(interactionName);
    const response = await action();
    fedopsLogger?.interactionEnded?.(interactionName);
    return response;
  } catch (err) {
    fedopsLogger?.interactionFailed?.(interactionName, err as Error);
    throw err;
  }
};

export const addSideCart = async (
  sdk: EditorSDK,
  managingAppDefId: string,
  isResponsive: boolean = false,
  t: (key: string) => string
) => {
  return createLightbox(sdk, managingAppDefId, isResponsive, t);
};

export async function addMembersArea(
  editorType: string,
  firstInstall: boolean,
  biData?: {origin?: string},
  siteCreationFlow?: boolean,
  fedopsLogger?: Record<string, any>
) {
  await toMonitored(fedopsLogger, FedopsInteractions.MemberAreaRegister, () =>
    registerMembersAreaApps(ECOM_MEMBERS_AREA_DEFAULT_PAGES)
  );

  if (!firstInstall || editorType === 'ADI' || siteCreationFlow) {
    return Promise.resolve();
  }

  const options = biData && {biData};
  await toMonitored(fedopsLogger, FedopsInteractions.MemberAreaInstallation, () => installMembersArea(options));

  await toMonitored(fedopsLogger, FedopsInteractions.MemberAreaAddApplication, () =>
    addApplications(ECOM_MEMBERS_AREA_DEFAULT_PAGES, options)
  );
}

export async function setStateForStoresPages(sdk: EditorSDK) {
  const allSitePages = await sdk.pages.data.getAll('');
  const storesPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === WIX_NEW_STORES);
  return Promise.all(
    storesPages.map((pageData) => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.CATEGORY ||
        pageData.tpaPageId?.startsWith(PageMap.GALLERY)
      ) {
        return sdk.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id} as PageRef],
          },
        });
      }
      return Promise.resolve();
    })
  );
}

export async function setStateForPages(sdk: EditorSDK) {
  const ecomPages = await getEcomPages(sdk);
  return Promise.all(
    ecomPages.map((pageData) => {
      if (pageData.tpaPageId === PageMap.CART || pageData.tpaPageId === PageMap.THANKYOU) {
        return sdk.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id} as PageRef],
          },
        });
      }
      return Promise.resolve();
    })
  );
}

export function openEcomBillingPage(editorSDK: EditorSDK) {
  return editorSDK.editor.openBillingPage('', {premiumIntent: 'PAID', referrer: 'stores_app_product'});
}

export async function installWishlistApp({
  sdk,
  dependantApps,
  t,
  webBiLogger,
}: {
  sdk: EditorSDK;
  dependantApps: DependantApps;
  t: (keyToTranslate: string) => string;
  webBiLogger: WebBiLogger;
}) {
  const wishlistApi = createWishlistApi({sdk, dependantApps, t, webBiLogger});
  if (await isMembersAreaInstalled()) {
    return wishlistApi.installWishlist({origin: InstallationOrigin.Plugins, installMembersArea: false});
  } else {
    return wishlistApi.installWishlist({origin: InstallationOrigin.Plugins, installMembersArea: true});
  }
}

export function updateEcomPlatformInstallationsData(
  ecomPlatformInstallationState: EcomPlatformInstallationState,
  appDefId: string,
  ecomComponentsData: EcomComponentsData
) {
  ecomPlatformInstallationState.addInstallAppComponentsData(appDefId, ecomComponentsData);
}

export async function updateEcomPagesForPagesPanel(
  editorSDK: EditorSDK,
  ecomPlatformInstallationState: EcomPlatformInstallationState
) {
  const managingAppDefId = ecomPlatformInstallationState.getTheAppManageAppId();

  const tpaApplicationId = (await editorSDK.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
  const allSitePages = await editorSDK.pages.data.getAll('');
  const popupPages = await editorSDK.pages.popupPages.getApplicationPopups('', {includeUnmanaged: true});
  const ecomPopupPages = popupPages?.filter((page: PopupData) => page.tpaApplicationId === tpaApplicationId) || [];
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);

  return Promise.all([
    ...ecomPages.map(async (pageData: PageData) => {
      const {tpaPageId, id} = pageData;

      if (tpaPageId === PageMap.CART || tpaPageId === PageMap.THANKYOU) {
        await updateEcomPages(id, editorSDK, managingAppDefId);
      }

      return editorSDK.pages.setState('', {
        state: {
          [tpaPageId]: [{id} as PageRef],
        },
      });
    }),
    ...ecomPopupPages.map(async (pageData: PopupData) => {
      const {tpaPageId, id} = pageData;

      if (tpaPageId === PageMap.SIDE_CART) {
        await updateEcomPages(id, editorSDK, managingAppDefId);
      }
    }),
  ]);
}

export async function getEcomPlatformAppManifest(t, locale) {
  if (!t) {
    t = await translateFunctionFactory(locale);
  }
  const ecomPages = getEcomPagesConfiguration(t, getAppVersion(), locale);

  return {
    pages: {
      pageActions: {
        [PageMap.CART]: ecomPages[PageMap.CART].actions,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].actions,
      },
      pageSettings: {
        [PageMap.CART]: ecomPages[PageMap.CART].settings,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].settings,
      },
      pageDescriptors: {
        [PageMap.CART]: ecomPages[PageMap.CART].descriptors,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].descriptors,
      },
    },
  };
}

export async function setThankYouPageStyleParams({
  sdk,
  ecomPlatformInstallationState,
  styleParams,
}: {
  sdk: EditorSDK;
  ecomPlatformInstallationState: EcomPlatformInstallationState;
  styleParams?: EcomStyleParams;
  experimentsApi: ExperimentsApi;
}) {
  if (!ecomPlatformInstallationState.isMoreThanOneAppInstalled()) {
    const applicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
    const styleParamsHandler = new StyleParams(sdk, applicationId);
    return styleParamsHandler.setThankYouPageStyleParams(styleParams);
  }
}

async function updateEcomPages(id: string, editorSDK: EditorSDK, managingAppDefId: string): Promise<void> {
  const pageRef: PageRef = {id, type: 'DESKTOP'};

  await editorSDK.pages.data.update('', {
    pageRef,
    data: {managingAppDefId},
  });
}
